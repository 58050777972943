import {useEffect, useRef, useState} from 'react';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import {truncate} from 'lodash';
import cn from 'classnames';

import Avatar from 'components/Avatar';

import MenuIcon from './MenuIcon';
import UserModal from './UserModal';

import useAuth from 'hooks/useAuth';
import {getFullName} from 'helpers/user';
import useUi from 'hooks/useUi';
import {MenuItemName} from 'types';

import logo from '../../assets/icon.png';
// import logo_expanded from './images/logo_magic_expand.png';

import styles from './Sidebar.module.scss';
import classNames from 'classnames';
import Onboarding from 'Onboarding';
import Guide from './Guide';

interface SubmenuItem {
  title: string;
  path: string;
  exact?: boolean;
}

interface MenuItem {
  name: MenuItemName;
  title: string;
  path: string;
  exact?: boolean;
  subitems?: SubmenuItem[];
}

const MENU_ITEMS: MenuItem[] = [
  {name: 'package', title: 'Offerings', path: '/products', exact: false},
  // {name: 'requests', title: 'Requests', path: '/requests'},
  {name: 'rx', title: 'Orders', path: '/orders'},
  {
    name: 'truck',
    title: 'Dispatch',
    path: '/deliveries/delivery_planner',
    // subitems: [
    //   {title: 'Delivery planner', path: '/deliveries/delivery_planner'},
    //   //{title: 'All deliveries', path: '/deliveries', exact: true},
    //   {title: 'Instore pickups', path: '/deliveries/in-store_pickups'},
    // ],
  },
  {
    name: 'transactions',
    title: 'Transactions',
    path: '/transactions',
    exact: false,
  },
  {
    name: 'customers',
    title: 'Customers',
    path: '/users',
  },
  {
    name: 'employees',
    title: 'Employees',
    path: '/employees',
  },
  {
    name: 'teams',
    title: 'Teams',
    path: '/vehicles',
  },
  {
    name: 'gateway',
    title: 'Settings',
    subitems: [
      {title: 'Billing', path: '/settings/billing'},
      // {title: 'Tariff', path: '/settings/tariff'},
      {title: 'Integrations', path: '/settings/gateway'},
      {title: 'Company', path: '/settings/company'},
    ],
    path: '/settings',
  },
];

function Sidebar() {
  const {currentUser} = useAuth();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const {pathname} = useLocation();
  const {push} = useHistory();

  const [showTooltip, setShowTooltip] = useState(false);
  const [guideOpen, setGuideOpen] = useState(false);

  const {sidebarExtended, setSidebarExtended} = useUi();
  const [showGuide, setShowGuide] = useState(false);

  const text = useRef(MENU_ITEMS[0].title);
  const top = useRef(0);

  useEffect(() => {
    if (!currentUser?.is_onboarded) {
      setGuideOpen(true);
    }
  }, [currentUser]);

  useEffect(
    function () {
      setIsUserModalOpen(false);
    },
    [pathname]
  );

  return (
    <>
      {showTooltip && sidebarExtended && (
        <span style={{top: 250 + top.current * 45}} className={styles.toolt}>
          {text.current}
        </span>
      )}
      <div className={cn(styles.root, {[styles['--narrow']]: sidebarExtended})}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img
            onClick={() => setSidebarExtended(!sidebarExtended)}
            className={cn(styles.logo, !sidebarExtended && styles.logoActive)}
            alt="magic logo"
            src={logo}
          />
          <p style={{color: '#fff', fontSize: 35}}>Magic</p>
        </div>
        <div className={styles.nav_padding} />
        <nav className={styles.nav}>
          <ul className={styles.menu}>
            {MENU_ITEMS.slice(
              0,
              currentUser?.role === 'management'
                ? MENU_ITEMS.length
                : MENU_ITEMS.length - 1
            ).map((item, index) => (
              <li
                onMouseEnter={() => {
                  text.current = item.title;
                  top.current = index;
                  setShowTooltip(true);
                }}
                onMouseLeave={() => {
                  setShowTooltip(false);
                }}
                className={classNames(
                  styles.menu_item,
                  `sidebar-menu-${index}`
                )}
                key={item.name}
              >
                <NavLink
                  to={item.path}
                  activeClassName={styles.active}
                  exact={item.exact}
                  style={{position: 'relative'}}
                >
                  <MenuIcon
                    className={styles.menu_item__icon}
                    name={item.name}
                  />
                  <span className={styles.text}>{item.title}</span>
                  {/* {sidebarExtended && (
                    <span className={styles.tooltiptext}>{item.title}</span>
                  )} */}
                </NavLink>

                {item.subitems && (
                  <ul
                    className={cn(styles.submenu, {
                      [styles['--hidden']]: !pathname.includes(item.path),
                    })}
                  >
                    {item.subitems.map((subitem) => (
                      <li className={styles.submenu__item} key={subitem.path}>
                        <NavLink
                          to={subitem.path}
                          activeClassName={styles['--active']}
                          exact={subitem.exact}
                        >
                          {subitem.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          {showGuide && (
            <Onboarding
              onFinish={() => {
                push('/deliveries/delivery_planner');
              }}
              steps={[
                {
                  target: '.sidebar-menu-7',
                  title: 'Settings',
                  disableBeacon: true,
                  content: 'You can manage your billing account from here',
                },
                {
                  target: '.sidebar-menu-6',
                  title: 'Teams',
                  disableBeacon: true,
                  content: 'You can create your team from here',
                },
                {
                  target: '.sidebar-menu-5',
                  title: 'Employes',
                  content: 'You can create your employe from here',
                },
                {
                  target: '.sidebar-menu-4',
                  title: 'Customer',
                  content: 'You can see your customers from here',
                },
                {
                  target: '.sidebar-menu-3',
                  title: 'Transactions',
                  content: 'You can check transactions from here',
                },
                {
                  target: '.sidebar-menu-2',
                  title: 'Dispatch',
                  content: 'You can assign task for your teams from here',
                },
                {
                  target: '.sidebar-menu-1',
                  title: 'Orders',
                  content: 'You can see your orders from here',
                },
                {
                  target: '.sidebar-menu-0',
                  title: 'Offerings',
                  content: 'You can manage your offerings from here',
                },
              ].reverse()}
            />
          )}
        </nav>
        <div className={styles.user_wrapper}>
          <div className={styles.user} onClick={() => setIsUserModalOpen(true)}>
            <div className={styles.user__details}>
              <span className={styles.user__name}>
                {getFullName(currentUser)}
              </span>
              <span className={styles.user__email}>
                {truncate(currentUser?.email, {length: 20, omission: '...'})}
              </span>
            </div>
            <Avatar
              className={styles.user__avatar}
              file={currentUser?.avatar}
            />
          </div>
        </div>
        <UserModal
          isOpen={isUserModalOpen}
          onClose={() => setIsUserModalOpen(false)}
        />
        <Guide
          onClose={(isStart) => {
            setShowGuide(isStart);
            setGuideOpen(false);
            if (isStart) {
              localStorage.setItem('show-onboarding', 'true');
            }
          }}
          isOpen={guideOpen}
        />
      </div>
    </>
  );
}

export default Sidebar;

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {add, format, formatISO, parseISO, parseJSON} from 'date-fns';
import toast from 'react-hot-toast';

// import useUi from 'hooks/useUi';
import {
  ListResponse,
  Order,
  Vehicle,
  RouteStatus,
  Driver,
  SelectOption,
} from 'types';
import api from 'api';
import {wait} from 'utils/dummy';

import useStore from './store';
import {useHistory, useLocation} from 'react-router-dom';
import useOrderList from 'hooks/useOrderList';
// import {orderSortFunctionMap} from 'utils/order-helpers';
import {get, reject} from 'lodash';
import {FilterOption} from './components/Filter/Filter';

export interface Route {
  id: number | string;
  driver?: Driver;
  team?: Vehicle;
  start_time: string | undefined;
  start_location: string;
  end_location: string;
  route_name: string;
  orders: Order[];
  deliveries: Order[];
  status: RouteStatus;
  total_time?: number;
  isClear?: boolean;
  start_latitude: number;
  start_longitude: number;
  end_latitude: number;
  end_longitude: number;
}
export interface DriverRoute {
  id: number;
  note: string;
}

const METERS_IN_MILE = 1609.34;

function roundToNearest30Minutes(roundedDate: Date) {
  const minutes = roundedDate.getMinutes();

  // Calculate the difference to the next 15-minute interval
  const remainder = minutes % 30;
  const difference = 30 - remainder;

  // Add the difference to the current time
  roundedDate.setMinutes(minutes + difference);

  return roundedDate;
}

const roundedTime = roundToNearest30Minutes(new Date());

let selectedTime = roundedTime.toISOString();

const defaultRoute: Route = {
  id: '0',
  start_time:
    format(Date.now(), 'yyyy-MM-dd') + selectedTime.slice(10, 19) + 'Z',
  start_location: '',
  end_location: '',
  route_name: '',
  orders: [],
  deliveries: [],
  status: 'new',
  start_latitude: 40.7596958,
  start_longitude: 73.9650045,
  end_latitude: 40.7596958,
  end_longitude: 73.9650045,
};

function getRequestData(route: Route) {
  return {
    ...route,
    team: route?.team?.id,
    orders: [],
    deliveries: route.deliveries.map((o) => o.id),
    // deliveries: route.orders.map((o) => o.id),
  };
}
function getRouteCreateData(route: Route) {
  return {
    ...route,
    id: undefined,
    orders: [],
    team: route.team?.id,
    deliveries: route.deliveries.map((o) => o.id),
  };
}

async function getVehicles() {
  const {data} = await api.get<ListResponse<Vehicle>>('/teams/');
  return data;
}

async function getRouteById({queryKey}: QueryFunctionContext) {
  const [, routeId] = queryKey;
  if (routeId && routeId !== '0') {
    const {data} = await api.get<Route>(`/routes/${routeId}/`);
    return data;
  }

  return defaultRoute;
}

export type SortOption = {
  value: string;
  label: string;
};

const sortOptions: SortOption[] = [
  {
    label: 'Most recent',
    value: 'most_recent',
  },
  {
    label: 'Alphabetical',
    value: 'alphabetical',
  },
];

function useDeliveryPlanner({
  setMoveToOrder,
}: {
  setMoveToOrder: (val: boolean) => void;
}) {
  const {search, pathname} = useLocation();
  const [sortBy, setSortBy] = useState<SortOption>(sortOptions[0]);

  const {push} = useHistory();
  const routeId =
    useMemo(() => new URLSearchParams(search).get('id'), [search]) ||
    sessionStorage.getItem('route_id') ||
    '0';

  const routeIdRef = useRef(routeId);
  const isInitial = useRef(true);

  useEffect(() => {
    if (routeIdRef.current !== '0') {
      push({search: `?id=${routeIdRef.current}`});
    }
  }, [push]);

  useEffect(() => {
    if (!search && routeIdRef.current !== '0') {
      if (!isInitial.current) {
        routeIdRef.current = '0';
        sessionStorage.setItem('route_id', '0');
      }
    }
    isInitial.current = false;
  }, [search]);

  const [currentPage, setCurrentPage] = useState(1);
  const [startTime, setStartTime] = useState(new Date());
  const [filter, setFilter] = useState<FilterOption[]>([]);
  const [date, setDate] = useState<undefined>(undefined);
  const [searchKey, setSearch] = useState<string>('');
  const [preferenceTime, setPreferenceTime] = useState<
    undefined | {value: string; label: string}
  >(undefined);
  const [priority, setPriority] = useState<SelectOption | undefined>();

  const distance = useStore(useCallback((state) => state.distance, []));

  const queryClient = useQueryClient();
  const {data: teams} = useQuery('teams', getVehicles);

  const {
    remove,
    orders: data,
    isLoading,
    hasNextPage,
    isFetching,
    count,
    fetchNextPage,
  } = useOrderList({
    filter: filter,
    unAssignedOnly: true,
    preference_date: date,
    preference_time: preferenceTime
      ? preferenceTime.value.toLowerCase()
      : undefined,
    priority: priority ? String(priority.value) : undefined,
    search: searchKey ? searchKey : undefined,
    sortBy: sortBy.value,
  });

  const [orders, setOrders] = useState(data);

  useEffect(() => {
    setOrders(data);
  }, [data]);

  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) {
      remove();
    }
    firstRender.current = false;
  }, [sortBy, remove]);

  let {data: route, refetch} = useQuery(['route', routeId], getRouteById, {
    initialData: defaultRoute,
    refetchOnWindowFocus: Boolean(routeId),
    refetchInterval: 0,
  });

  const handleChangeFilter = useCallback((value: FilterOption, meta: any) => {
    if (value) {
      setFilter((prev) =>
        prev.some((item) => item.label === value.label)
          ? reject(prev, value)
          : [...prev, value]
      );
    } else {
      setFilter([]);
    }
  }, []);

  const onChangeData = useCallback((value: any) => {
    setDate(value);
  }, []);

  const onChangePriority = useCallback((value: SelectOption | null) => {
    setPriority(value || undefined);
  }, []);

  const updateRoute = useMutation(
    // 'update_route',
    async function (nextRoute: Route) {
      const nextRouteData = getRequestData({
        ...route,
        ...nextRoute,
      });

      // save changes if route is already created
      if (route?.id && route?.id !== '0' && !nextRoute?.isClear) {
        const {data} = await api.patch<Route>(
          `/routes/${route?.id}/`,
          nextRouteData
        );
        return data;
      }

      // just return changes if route is not created
      return {
        ...route,
        ...nextRoute,
      };
    },

    {
      mutationKey: 'update_route',
      onMutate: async (nextRoute) => {
        const previousRoute = queryClient.getQueryData<Route>([
          'routes',
          routeId,
        ]);

        await queryClient.cancelQueries(['route', routeId]);

        queryClient.setQueryData(['route', routeId], {
          ...route,
          ...nextRoute,
        });

        return {previousRoute};
      },

      onSettled: (data) => {
        if (!data) {
          queryClient.invalidateQueries(['route', routeId]);
        }
      },
    }
  );

  const [selectedUnassignedOrders, setSelectedUnassignedOrders] = useState<
    number[]
  >([]);
  const [selectedAssignedOrders, setSelectedAssignedOrders] = useState<
    number[]
  >([]);

  const unassignedOrders = useMemo(
    function () {
      if (orders) {
        const routeOrders = route?.deliveries.map((o) => o.id) || [];

        const unsorted = [...orders].filter((o) => !routeOrders.includes(o.id));
        // .filter((el) => !el.processed)
        // .filter(filterOrder(filter));

        // if (sortBy) {
        //   return unsorted.sort(orderSortFunctionMap[sortBy.value]);
        // }

        return unsorted;
      }

      return [];
    },
    [orders, route?.deliveries]
  );

  const handleSendRoute = useCallback(
    async (updatedRoute: Route) => {
      await api.post(`/routes/${updatedRoute.id}/send/`, {
        route_name: updatedRoute?.route_name,
      });
      push(pathname);
      sessionStorage.setItem('route_id', '0');

      defaultRoute.deliveries = [];

      updateRoute.mutate({
        ...updatedRoute,
        id: '0',
        status: 'new',
        deliveries: [],
        isClear: true,
        start_time: '',
      });

      setOrders(unassignedOrders);
      setSelectedAssignedOrders([]);

      queryClient.invalidateQueries(['orders']);
    },
    [pathname, push, queryClient, unassignedOrders, updateRoute]
  );

  // useEffect(
  //   function () {
  //     setSidebarExtended(false);
  //     setFullsizeContent(true);

  //     return function () {
  //       setSidebarExtended(true);
  //       setFullsizeContent(false);
  //     };
  //   },
  //   [setSidebarExtended, setFullsizeContent, route]
  // );

  function onChangeUnassignedCheckbox(id: number, checked: Boolean) {
    if (checked) {
      setSelectedUnassignedOrders([...selectedUnassignedOrders, id]);
    } else {
      setSelectedUnassignedOrders(
        selectedUnassignedOrders.filter((selected) => selected !== id)
      );
    }
  }

  const [isFetchAll, setIsFetchAll] = useState(false);
  const [fetchingAll, setFetchingAll] = useState(false);
  const checkedAll = useRef(false);

  useEffect(() => {
    (async () => {
      if (isFetchAll && hasNextPage) {
        setFetchingAll(true);
        try {
          await fetchNextPage();
        } catch (error) {}
      } else {
        setFetchingAll(false);
      }
    })();

    if (checkedAll.current) {
      setSelectedUnassignedOrders(orders.map((item) => item.id));
    }
  }, [isFetchAll, orders, hasNextPage, fetchNextPage]);

  async function onChangeUnassignedCheckboxAll(
    ids: number[],
    checked: boolean
  ) {
    if (checked) {
      setSelectedUnassignedOrders(ids);
    } else {
      setSelectedUnassignedOrders([]);
    }

    checkedAll.current = checked;
    setIsFetchAll(true);
  }

  function onChangeAssignedCheckbox(id: number, checked: boolean) {
    if (checked) {
      setSelectedAssignedOrders([...selectedAssignedOrders, id]);
    } else {
      setSelectedAssignedOrders(
        selectedAssignedOrders.filter((selected) => selected !== id)
      );
    }
  }

  function onChangeAssignedCheckboxAll(ids: number[], checked: boolean) {
    if (checked) {
      setSelectedAssignedOrders(ids);
    } else {
      setSelectedAssignedOrders([]);
    }
  }

  function onChangeRouteParams(
    start_location: {
      name: string;
      lat: number;
      lng: number;
    },
    end_location: {
      name: string;
      lat: number;
      lng: number;
    },
    currentTeam?: Vehicle
  ) {
    if (!route) return;

    updateRoute.mutate({
      ...route,
      start_location: start_location.name,
      start_time: startTime ? formatISO(startTime) : undefined,
      end_location: end_location.name,
      team: currentTeam,
      start_latitude: start_location.lat,
      start_longitude: start_location.lng,
      end_latitude: end_location.lat,
      end_longitude: end_location.lng,
    });
    defaultRoute.team = currentTeam;
    defaultRoute.start_location = start_location.name;
    defaultRoute.end_location = end_location.name;
    defaultRoute.start_latitude = start_location.lat;
    defaultRoute.start_longitude = start_location.lng;
    defaultRoute.end_latitude = end_location.lat;
    defaultRoute.end_longitude = end_location.lng;
  }

  function onChangeStartTime(value: Date) {
    if (!route) return;
    setStartTime(value);

    updateRoute.mutate({
      ...route,
      start_time: value ? formatISO(value) : undefined,
    });
  }

  function assignSelected() {
    if (!route) return;

    updateRoute.mutate({
      ...route,
      deliveries: [
        ...route.deliveries,
        ...unassignedOrders.filter((o) =>
          selectedUnassignedOrders.includes(o.id)
        ),
      ],
    });

    defaultRoute.deliveries = [
      ...route.deliveries,
      ...unassignedOrders.filter((o) =>
        selectedUnassignedOrders.includes(o.id)
      ),
    ];

    if (hasNextPage) {
      fetchNextPage();
    }

    setSelectedUnassignedOrders([]);
  }

  async function moveToOrders() {
    if (!route) return;

    try {
      await api.post(`/routes/deliveries/move-to-orders/`, {
        order_ids: selectedUnassignedOrders,
      });
      setMoveToOrder(false);
      setOrders((pr) =>
        pr.filter((item) => !selectedUnassignedOrders.includes(item.id))
      );
      setSelectedUnassignedOrders([]);
    } catch (error) {}
  }

  function unassignSelected() {
    if (!route) return;

    defaultRoute.deliveries = route?.deliveries.filter(
      (o) => !selectedAssignedOrders.includes(o.id)
    );
    updateRoute.mutate({
      ...route,
      deliveries: route?.deliveries.filter(
        (o) => !selectedAssignedOrders.includes(o.id)
      ),
    });

    setSelectedAssignedOrders([]);
  }

  const resetCalculate = () => {
    push({search: ''});
    sessionStorage.setItem('route_id', '0');
    if (route)
      updateRoute.mutate({
        ...route,
        deliveries: [],
        status: 'new',
      });
    defaultRoute.deliveries = [];
  };

  // const calculate = useCallback(async () => {
  //   let id = route?.id;
  //   // if route is not created, create it
  //   if (route && !route.id) {
  //     const {data} = await api.post<Route>('/routes/', getRequestData(route));
  //     id = data.id;
  //   }
  //
  //   // initiate task to calculate the route
  //   await api.post(`/routes/${id}/calculate/`);
  //   let pendingRoute = route;
  //
  //   do {
  //     await wait(5000);
  //     const {data} = await api.get<Route>(`/routes/${id}/`);
  //     pendingRoute = data;
  //     alertUser(data.status);
  //   } while (pendingRoute.status === 'processing')
  //   push({search: `?id=${id}`});
  // }, [startTime, route])

  async function sendDriverNote(driverNote: DriverRoute) {
    return await api.post(`/routes/deliveries/staff_note/${driverNote.id}/`, {
      staff_note: driverNote.note,
    });
  }

  const [calculating, setCalculating] = useState(false);

  async function calculate() {
    let id = route?.id;

    if (!route?.start_location) {
      return toast.error(
        'Address or team was not assigned, Please assign address and team!'
      );
    }
    // if route is not created, create it
    if (route && (!route.id || route.id === '0')) {
      if (route?.deliveries.length >= 40) {
        setCalculating(true);
      }

      try {
        const {data} = await api.post<Route>(
          '/routes/',
          getRouteCreateData(route)
        );
        id = data.id;
      } catch (error) {
        toast.error('Error while creating route, Please try again!');
      }
    }
    if (id === '0') {
      return;
    }

    // @ts-ignore
    const address_ids = route?.deliveries.map((order) =>
      String(get(order, 'delivery_address.id', ''))
    );
    const order_ids = route?.deliveries.map((order) =>
      String(get(order, 'id', ''))
    );

    // initiate task to calculate the route
    try {
      await api.post(`/routes/${id}/calculate/`, {
        address_ids: route?.status !== 'new' ? address_ids : [],
        order_ids: route?.status !== 'new' ? order_ids : [],
        start_location: route?.start_location,
        end_location: route?.end_location,
        start_time: route.start_time,
        start_latitude: route.start_latitude,
        start_longitude: route.start_longitude,
        end_latitude: route.end_latitude,
        end_longitude: route.end_longitude,
        team: route.team?.id,
      });
    } catch (error) {
      toast.error('Error, Please try again!');
      setCalculating(false);
    }

    let pendingRoute = route;

    do {
      await wait(5000);
      try {
        const {data} = await api.get<Route>(`/routes/${id}/`);
        pendingRoute = data;

        alertUser(data.status, get(data, 'error_message', ''));
        if (data.status === 'processed') {
          updateRoute.mutate(data);
          setCalculating(false);
        }
      } catch (error) {
        setCalculating(false);
      }
    } while (pendingRoute.status === 'processing');

    if (id) {
      defaultRoute.deliveries = [];
      push({search: `?id=${id}`});
      routeIdRef.current = String(id);
      sessionStorage.setItem('route_id', String(id));
    }
  }

  function alertUser(status: string, message: string) {
    if (status === 'cancelled') {
      setCalculating(false);
      toast.error(message || 'Wrong address, Please check attached addresses!');
    }
  }
  function statsCalculator(route: undefined | Route) {
    return {
      stops: route?.deliveries.length,
      distance: `${Math.round((distance * 10) / METERS_IN_MILE) / 10} mi`,
      total_time: route?.total_time
        ? `${Math.floor(route.total_time / 60)}h ${route.total_time % 60}m`
        : '',
      end_time:
        route?.start_time && route.total_time
          ? format(
              add(parseISO(route.start_time), {minutes: route.total_time}),
              'h:mm aaa'
            )
          : '-:-- --',
    };
  }

  return {
    route: route ? route : defaultRoute,
    currentTeam: teams?.results.find((v) => v.id === route?.team?.id),
    teams,
    unassignedOrders,
    isLoading,
    selectedUnassignedOrders,
    selectedAssignedOrders,

    stats: statsCalculator(route),

    isSubmittable: Boolean(
      route &&
        route.start_location &&
        route.end_location &&
        route.start_time &&
        !!route?.team
    ),

    onChangeUnassignedCheckbox,
    onChangeUnassignedCheckboxAll,
    onChangeAssignedCheckbox,
    onChangeAssignedCheckboxAll,
    onChangeRouteParams,
    onChangeStartTime,
    onSendRoute: handleSendRoute,
    count: count - (route?.deliveries.length || 0),
    sortBy,
    setSortBy,
    refetch,
    filter,
    onFilterChange: handleChangeFilter,
    onChangeData,
    assignSelected,
    moveToOrders,
    unassignSelected,
    sendDriverNote,
    calculate,
    date,
    setPreferenceTime,
    preferenceTime,
    setSelectedUnassignedOrders,
    setSelectedAssignedOrders,
    currentPage,
    setCurrentPage,
    hasNextPage,
    fetchNextPage,
    isFetching,
    roundedTime: route?.start_time
      ? new Date(parseJSON(route?.start_time)).setHours(0, 0, 0, 0) >
        new Date().setHours(0, 0, 0, 0)
        ? undefined
        : roundedTime
      : roundedTime,
    setSearch,
    searchKey,
    fetchingAll,
    calculating,
    updateRoute,
    resetCalculate,
    sortOptions,
    search,
    defaultRoute,
    priority,
    onChangePriority,
  };
}

export default useDeliveryPlanner;

import Button from 'components/Button';
import {useHistory} from 'react-router-dom';

const BillingDetails = () => {
  const {push} = useHistory();

  const goDetails = () => {
    push('/settings/billing/update');
  };

  return (
    <div style={styles.container}>
      <h1>Billing details</h1>

      <div style={styles.section}>
        <div style={styles.currentPlan}>
          <div>
            <h2>Solo plan with 2 seats</h2>
            <p>Bills monthly at $23.95 USD per month</p>
            <p>Renews on 25 Oct 2024</p>
            <p>Supports up to 2 people (2 currently active)</p>
            <a href="#history" style={styles.link}>
              See billing history
            </a>
          </div>
          {/* @ts-ignore */}
          <div style={styles.actions}>
            <Button
              color="green"
              onClick={() => {
                push('/settings/tariff');
              }}
            >
              Update plan
            </Button>
            <Button color="green" onClick={goDetails}>
              Add or remove seats
            </Button>
            <Button color="green" onClick={() => {}}>
              Pay yearly
            </Button>
          </div>
        </div>
      </div>

      <div style={styles.section}>
        <div style={styles.tableRow}>
          <p style={{flex: 1}}>
            <b>Payment method</b>
          </p>
          <p style={{flex: 1}}>3245 **** **** 3439</p>
          <p style={{flex: 1, textAlign: 'right'}}>
            <a href="#credit" style={styles.link}>
              Update credit card
            </a>
          </p>
        </div>
        <div style={styles.tableRow}>
          <p style={{flex: 1}}>
            <b>Receipt recipients</b>
          </p>
          <p style={{flex: 1}}>nanowebgroup.harvestapp.com</p>
          <p style={{flex: 1, textAlign: 'right'}}>
            <a href="#recipients" style={styles.link}>
              Update recipients
            </a>
          </p>
        </div>
        <div style={styles.tableRow}>
          <p style={{flex: 1}}>
            <b>Additional details</b>
          </p>
          <p style={{flex: 1}}>No additional details</p>
          <p style={{flex: 1, textAlign: 'right'}}>
            <a href="#details" style={styles.link}>
              Add details
            </a>
          </p>
        </div>
        <div style={styles.tableRow}>
          <p style={{flex: 1}}>
            <b>Web address</b>
          </p>
          <p style={{flex: 1}}>Update address</p>
          <p style={{flex: 1, textAlign: 'right'}}>
            nanowebgroup.harvestapp.com
          </p>
        </div>
        <div style={styles.tableRow}>
          <p style={{flex: 1}}>
            <b>Account created</b>
          </p>
          <p style={{flex: 1}}>22 Feb 2013</p>
          <p style={{flex: 1}}></p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '80%',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f9f9f9',
  },
  section: {
    marginBottom: '20px',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  currentPlan: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableRow: {
    display: 'flex',
    borderBottom: '1px solid #e0e0e0',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default BillingDetails;

import Button from 'components/Button';

type ItemProps = {
  title: string;
  price: string;
  people: string;
  additionalPeopleInfo: string;
  buttonText: string;
};
const PlanCard = ({
  title,
  price,
  people,
  additionalPeopleInfo,
  buttonText,
}: ItemProps) => {
  return (
    // @ts-ignore
    <div style={styles.card}>
      <h3>{title}</h3>
      <p>Start with {people} for</p>
      <h2 style={styles.price}>
        ${price}
        <span style={styles.perMonth}>/month</span>
      </h2>
      <p>{additionalPeopleInfo}</p>
      <Button style={{marginLeft: 'auto', marginRight: 'auto'}} color="green">
        {buttonText}
      </Button>
    </div>
  );
};

const PricingPlans = () => {
  return (
    //@ts-ignore
    <div style={styles.container}>
      <h2>Choose your plan</h2>
      <p>All paid plans include unlimited projects, clients, and invoices.</p>
      {/* @ts-ignore */}
      <div style={styles.plans}>
        <PlanCard
          title="Solo"
          price="12"
          people="1 person"
          additionalPeopleInfo="Add up to 2 additional people for an extra $10 per person per month"
          buttonText="Choose Solo"
        />
        <PlanCard
          title="Basic"
          price="49"
          people="5 people"
          additionalPeopleInfo="Add up to 5 additional people for an extra $10 per person per month"
          buttonText="Choose Basic"
        />
        <PlanCard
          title="Business"
          price="99"
          people="10 people"
          additionalPeopleInfo="Add unlimited people for an extra $10 per person per month"
          buttonText="Choose Business"
        />
      </div>

      <div style={styles.footer}>
        <p>
          We also offer a <a href="#free">Free plan</a> which provides 1 person
          and 2 projects.
        </p>
        <p>
          Active people are people who can sign in to Harvest to track time and
          expenses, or perform project management and administrative duties. You
          can archive people to free up active user slots and add new people.
        </p>
        <p>
          You can only choose a plan that fits the number of active people you
          currently have. Questions? <a href="#free">Get in touch with us.</a>
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    alignItems: 'center',
    padding: '20px',
    justifyContent: 'center',
    height: '88vh',
    display: 'flex',
    flexDirection: 'column',
  },
  plans: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    marginTop: '20px',
    flexWrap: 'wrap',
  },
  card: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '20px',
    width: '100%',
    minWidth: 300,
    maxWidth: '25%',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  price: {
    fontSize: '32px',
    margin: '10px 0',
  },
  perMonth: {
    fontSize: '16px',
    color: '#888',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  footer: {
    marginTop: '20px',
    fontSize: '14px',
    color: '#666',
  },
};

export default PricingPlans;
